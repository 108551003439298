export const accountTypes = [
  {
    label: 'individual',
    value: 'individual',
  },
  {
    label: 'nvo',
    value: 'nvo',
  },
  {
    label: 'education',
    value: 'education',
  },
  {
    label: 'company',
    value: 'company',
  },
  {
    label: 'media',
    value: 'media',
  },
]

export const genders = [
  {
    label: 'М',
    value: 'Male',
  },
  { label: 'Ж', value: 'Female' },
]
