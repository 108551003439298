import * as yup from 'yup'

const myDate = () =>
  yup
    .date()
    .transform((_, originalValue) => {
      if (!originalValue) {
        return false
      }
      return new Date(originalValue)
    })
    .typeError('Invalid date format')
    .required('requiredInput')

export const CompleteProfileSchema = yup.object().shape({
  municipality: yup.number().required('requiredInput'),
  accountType: yup.string().required('requiredInput'),
  birth: yup.date().when('accountType', {
    is: (type) => type === 'individual',
    then: myDate()
      .min(new Date('1900-01-01'), 'enterValidYear')
      .max(new Date('2017-01-01'), 'enterValidYear')
      .typeError('requiredInput')
      .required('requiredInput'),
    otherwise: yup.date().nullable().notRequired(),
  }),
  firstName: yup.string().when('accountType', {
    is: (type) => type === 'individual',
    then: yup.string().trim().required('requiredInput'),
    otherwise: yup.string().notRequired(),
  }),
  familyName: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type === 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  gender: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type === 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  companyName: yup
    .string()
    .trim()
    .when('accountType', {
      is: (type) => type !== 'individual',
      then: yup.string().trim().required('requiredInput'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  industry: yup.number().when('accountType', {
    is: (type) => type !== 'individual',
    then: yup.number().required('requiredInput'),
    otherwise: yup.number().nullable().notRequired(),
  }),
  interests: yup.array().notRequired(),
})
